import request from '@/utils/request'
// 商户商品列表
export function getGoodsList(data) {
  return request('post', '/apm/merchant/goods/page', data)
}

// 新增商户商品
export function getGoodsAdd(data) {
  return request('post', '/apm/merchant/goods/create', data)
}

// 查询商户商品详情
export function getGoodsInfo(data) {
  return request('post', '/apm/merchant/goods/findById', data)
}

// 编辑商户商品
export function getGoodsEdit(data) {
  return request('post', '/apm/merchant/goods/updateById', data)
}

// 编辑商品进销损
export function getInventoryEdit(data) {
  return request('post', '/apm/merchant/goods/inventory/update', data)
}